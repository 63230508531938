import { nuxtServerInit } from '@Core/store/initial'

export const types = {}

export const state = () => ({})

export const getters = {}

export const actions = {
  async nuxtServerInit(context, { app, error }) {
    await nuxtServerInit(context)
  },
}
