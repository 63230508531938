import Vue from 'vue'
import Router from 'vue-router'

import { extendRoutes, setLocalMiddlewares } from '~/.nuxt/routerHelper.modules'

import scrollBehavior from '~/app/router.scrollBehavior'

Vue.use(Router)

export function createRouter() {
  const router = new Router({
    mode: 'history',
    base: '/',
    scrollBehavior,
    routes: extendRoutes(),
    fallback: false,
  })

  router.onError((error) => {
    const message = error && error.message
    if (/chunk \d* failed./i.test(message.toLowerCase())) {
      if (!process.server) {
        const urlParams = new URLSearchParams(window.location.search)
        if (!urlParams.has('reload')) {
          window.location = `${window.location.href.split('?')[0]}?reload=1`
        }
      }
    }
  })

  router.beforeEach((to, from, next) => {
    setLocalMiddlewares({
      to,
      from,
      next,
    })
  })

  return router
}
